import React from "react";
import { Link } from "react-router-dom";
import useUserStore from "../../../../../store/UserStore";

function PostPersonas({
  personas,
  postId,
}: {
  postId: string | number;
  personas: { username: string; profileUrl: string; _id: string }[];
}) {
  const { activeUsers } = useUserStore();
  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between md:justify-start gap-y-2 md:gap-x-4">
      {personas.map((p, idx) => (
        <Link
          to={`/profile/${p.username}`}
          key={postId + "_" + p.username + idx}
          className="flex relative gap-x-1"
        >
          <div className="min-w-10 w-10 min-h-10 h-10 bg-gray-500 rounded-full overflow-clip flex items-center">
            <img loading="lazy" src={p.profileUrl} alt={p.username} />
          </div>
          <div className="space-y-1">
            <h4 className="text-[#48555B] text-xs">{p.username}</h4>
            <p className="text-[#48555B] text-xs">
              {activeUsers[p._id] ? (
                <span className="bg-orange-500 rounded-full absolute -left-1 top-0 w-3 h-3"></span>
              ) : (
                <span className=" text-xs">Offline</span>
              )}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default PostPersonas;
