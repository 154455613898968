import toast from "react-hot-toast";
import { follow, unFollow } from "./UserService";

export const handleFollowAction = async ({
  isFollowed,
  user,
  currentUser,
  setCurrentUser,
}) => {
  const action = isFollowed ? "unfollow" : "follow";
  const toastId = toast.loading(`${action}ing...`);
  try {
    if (action === "follow") {
      await follow(user, currentUser, setCurrentUser);
    }
    if (action === "unfollow") {
      await unFollow(user, currentUser, setCurrentUser);
    }
    toast.success(`${action}ed successfully!!`, {
      id: toastId,
    });
  } catch (error) {
    toast.error(`Error while trying to ${action}`, {
      id: toastId,
    });
    console.error(error);
  }
};
