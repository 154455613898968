import { isLoggedIn } from "../helpers/authHelper";
import { getCookie } from "../helpers/cookieHelper";
import { get, post, put } from "../lib/Fetcher";
import { Follow, UserDTO, UserDetails } from "../types/globals";

export const follow = async (
  user: Follow,
  prevState: UserDetails,
  setState: (value: UserDetails) => void
) => {
  await post(
    `api/follows/follow`,
    {
      followingId: user._id,
    },
    isLoggedIn().token
  );
  setState({
    ...prevState,
    followingsCount: ++prevState.followingsCount,
    followings: [
      ...prevState.followings,
      {
        _id: user._id,
        username: user.username,
      },
    ],
  });
};

export const unFollow = async (
  user: Follow,
  prevState: UserDetails,
  setState: (value: UserDetails) => void
) => {
  await post(
    `api/follows/unfollow`,
    {
      followingId: user._id,
    },
    isLoggedIn().token
  );
  setState({
    ...prevState,
    followings: prevState.followings.filter((u) => u._id !== user._id),
    followingsCount: --prevState.followingsCount,
  });
};

export const getUsersList = async () => {
  return await get(`api/users/list`, isLoggedIn()?.token);
};

export const getNotifications = async () => {
  const token = getCookie("auth_token");
  if (!token) return [];
  return await get(`api/notifications`, token);
};
export const markNotification = async (notificationId: string) => {
  const res = await put(
    `api/notifications/${notificationId}/mark-read`,
    {},
    getCookie("auth_token")
  );
  return res;
};

export const getUsersByAction = async (
  userId: string,
  action: "followers" | "followings"
) => {
  const res = await get(`api/users/${userId}/${action}`);
  return res as Promise<UserDTO[]>;
};

export const getUserPosts = async (id: string) => {
  return await get(`api/users/${id}/public/posts`);
};
