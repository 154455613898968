import React, { useState, useEffect } from "react";
import "./views/Questionnaire.css";
import {
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  fontFamily: "Montserrat",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  border: "2px solid #f9f5f7",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  background: "#01030b",
  color: "#fff",
  borderRadius: "10px",
};

const TextBoxQuestionnaire = (props) => {
  let question = props.q;

  return (
    <>
      <Typography
        variant="h6"
        color="black"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <div className="numberCircle">{question.id}</div>
        {question.question}
      </Typography>
      <TextField
        className="backgroundYuh"
        type="text"
        fullWidth
        onKeyDown={(e) => props.onKeyDown(e)}
        multiline={true}
        rows={3}
        required
        InputLabelProps={{ style: { color: "white" } }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0px solid white",
            
          },

          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: "0px solid white",
              
            },
          },
          "&:hover fieldset": {
            borderColor: "black !important",
          },
          input: {
            backgroundColor: "black !important",
            "&::placeholder": {
              color: "black",
            },
          },
          label: { color: "black" },
        }}
        name={props.name}
        value={props.value}
        onChange={(e) => props.onChange(e, props.name)}
        readOnly={!props.readOnly}
        placeholder={question.hint}
      />
    </>
  );
};

export default TextBoxQuestionnaire;
