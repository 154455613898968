import { BASE_URL } from "../config";
import { getCookie } from "../helpers/cookieHelper";
import { get, post, put } from "../lib/Fetcher";

const signup = async (user) => {
  try {
    return await post("api/auth/register", user);
  } catch (err) {
    console.log(err);
  }
};

const userAccountType = async (userId, value) => {
  console.log("accountype", userId, value);
  try {
    const response = await put(
      `api/users/account-type`,
      {
        userId: JSON.parse(localStorage.getItem("user")).userId,
        businessValue: value,
      },
      getCookie("auth_token")
    );
    console.log("response", response);

    return response;
  } catch (err) {
    console.log(err);
  }
};
const login = async (user) => {
  try {
    const res = await post("api/auth/login", user);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const getUser = async ({ username }) => {
  try {
    const res = await fetch(BASE_URL + "api/users/" + username);
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUserDetails = async (userID) => {
  try {
    const data = await get(`api/users/${userID}/details`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

const updateUser = async (userId, data) => {
  try {
    const res = await put(`api/users/${userId}`, data, getCookie("auth_token"));
    return res;
  } catch (err) {
    console.log(err);
  }
};

const updatePersona = async (user, data, token = "") => {
  try {
    const res = await fetch(BASE_URL + "api/users/persona", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token || getCookie("auth_token"),
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const joinCommunity = async (userId, communityId) => {
  try {
    const res = await fetch(BASE_URL + "api/users/community/join", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token"),
      },
      body: JSON.stringify({ userId, communityId }),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const unjoinCommunity = async (userId, communityId) => {
  try {
    const res = await fetch(BASE_URL + "api/users/community/unjoin", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token"),
      },
      body: JSON.stringify({ userId, communityId }),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const deactivateUserAccount = async (userId) => {
  try {
    const response = await fetch(BASE_URL + `api/users/deactivate/${userId}`, {
      method: "PUT", 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token"), 
      },
    });
    if (!response.ok) {
      throw new Error('Failed to deactivate user account');
    }
    return await response.json();
  } catch (err) {
    console.error("Error deactivating user account:", err);
    throw err;
  }
};
export {
  signup,
  login,
  getUser,
  updateUser,
  updatePersona,
  getUserDetails,
  joinCommunity,
  unjoinCommunity,
  userAccountType,
  deactivateUserAccount
};
