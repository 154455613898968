import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import PostPersonas from "./TopicPersonas";
type TopicContentProps = {
  _id: string | number;
  topic: string;
  content: string;
  personas: { profileUrl: string; username: string; _id: string }[];
};
function TopicContent({ _id, topic, content, personas }: TopicContentProps) {
  const shortContent = useMemo(() => {
    const [firstLine, secondLine, thirdLine] = content.split("-");
    return [firstLine, secondLine, thirdLine];
  }, [content]);
  return (
    <div className="space-y-2">
      <h4 className="text-gray-700 dark:text-gray-500  text-sm truncate max-w-[10rem] md:max-w-sm md:text-xl">
        {topic}
      </h4>
      <div className="hidden md:block">
        {shortContent.map((c) => (
          <p
            key={_id + "_" + c}
            className="dark:text-gray-400 text-xs my-[1px]"
          >
            {c}
          </p>
        ))}
      </div>
      <Link
        to={`/news-feed/detail/${_id}`}
        className="text-[#48555C] duration-100 hover:text-gray-400 text-sm"
      >
        Read more..
      </Link>
      <PostPersonas personas={personas} postId={_id.toString()} />
    </div>
  );
}

export default TopicContent;
