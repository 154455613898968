import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Button } from "@mui/material";

const ContactForm = () => {
    const [sent, setSent] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        console.log("Attempting to send email...");
        e.preventDefault();
        emailjs.sendForm(
            "service_reumgtk",
            "template_xu7khsl",
            e.target,
            "Ut59ThXub3-44Al_6"
        )
            .then(() => {
                document.getElementById("contact_form").reset();
                setSent(true);
                alert('Thank you for the inquiry, we will get back to you as soon as possibl.');
            },
                (error) => {
                    console.error(error);
                    setSent(false);
                }
            );
    };

    return (
        <div>
            <div className="w-full flex justify-center">
                <form
                    id="contact_form"
                    ref={form}
                    method="POST"
                    target="_blank"
                    onSubmit={sendEmail}
                    style={{ width: "250px" }}
                >
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <p>Check out our <a href="https://afterflea.com/FAQ" target="_blank" style={{ color: '#007bff', textDecoration: 'none' }}>FAQs</a> for answers to commonly asked questions. Don't see your question? Feel Free to send PersonaNet any inquiries that you may have.</p>
                    </div>
                    <div style={{ color: "white" }}>Contact PersonaNet</div>
                    <div className="w-full flex flex-col">
                        <label style={{ color: "white" }} htmlFor="name">name</label>
                        <input
                            placeholder="Enter your Name"
                            id="name"
                            type="text"
                            name="from_name"
                            required
                        />
                    </div>
                    <div className="w-full flex flex-col" >
                        <label style={{ color: "white" }} htmlFor="email">E-mail</label>
                        <input
                            placeholder="Enter your E-mail"
                            id="email"
                            type="email"
                            name="from_email"
                            required
                        />
                    </div>
                    <div className="w-full flex flex-col">
                        <label style={{ color: "white" }}>Message</label>
                        <textarea
                            placeholder="Enter your Message"
                            name="message"
                            required
                        ></textarea>
                    </div>
                    <Button className="w-full flex justify-center">
                        <input
                            type="submit"
                            value={!sent ? 'Send' : 'Done!'}
                        />
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;