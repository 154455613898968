import React, { useEffect, useState } from "react";
import { get } from "../../lib/Fetcher";
import useUserStore from "../../store/UserStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginUser } from "../../helpers/authHelper";
import { convertToken } from "../../helpers/jwtHelper";
import { UserDetails } from "../../types/globals";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { userAccountType } from "../../api/users";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { getCookie, setCookie } from "../../helpers/cookieHelper";
import { checkRedirectUrl } from "../../helpers/checkRedirectUrl";

const style = {
  fontFamily: "Roboto Condensed",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height:"280px",
  border: "2px solid #f9f5f7",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  background: "#7d7d7d",
  color: "#fff",
  borderRadius: "10px",
};

function UserAccountTypeView() {
  const { currentUser, setCurrentUser } = useUserStore();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [displayAccountPopUp, setDisplayAccountPopUp] = useState(false);

  useEffect(function getUserByToken() {
    setLoading(true);
    const token = searchParams.get("token") || "";
    console.log('searchParams.get("token")', token);
    get("api/auth/user", token)
      .then((data) => {
        console.log("data accountytpe", data);
        loginUser({
          ...convertToken(token),
          token,
        });
        setCurrentUser(data);
        setLoading(false);
        if (!data.hasOwnProperty("businessAccount")) {
          setDisplayAccountPopUp(true);
        } else {
          setDisplayAccountPopUp(false);
          setCookie({
            name: "businessAccount",
            value: JSON.stringify(data.businessAccount),
          });
          if (data.persona !== "") {
            checkRedirectUrl(navigate, () => navigate(`/feed`));
          } else {
            if (data.businessAccount) {
              navigate(`/persona/business/?username=${data.username}`);
            } else {
              navigate(`/persona/individual/?username=${data.username}`);
            }
            return;
          }
        }
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  function handleAccountTypeChange(value) {
    console.log("currentUser.userId", currentUser);
    try {
      userAccountType(currentUser._id, value).then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          console.log("currentUser.userId data", data);
          setCookie({
            name: "businessAccount",
            value: JSON.stringify(value),
          });
          setDisplayAccountPopUp(false);
          if (value) {
            navigate(`/persona/business/?username=${data.username}`);
          } else {
            navigate(`/persona/individual/?username=${data.username}`);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const closeAccountPopUp = (event, reason) => {
    if (reason !== "backdropClick") {
      setDisplayAccountPopUp(false);
    }
  };

  return (
    <>
      {/* <div className="text-white">{loading ? "Loading..." : "DOne"}</div> */}
      {displayAccountPopUp && (
        <Modal
          open={true}
          onClose={closeAccountPopUp}
          disableBackdropClick
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              display="flex"
              alignItems="center"
              style={{
                
              }}
            >
              <Box flexGrow={1}> Welcome to PersonaNet! </Box>
              <Box>
                <IconButton onClick={closeAccountPopUp}>x</IconButton>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              columnGap={8}
              style={{
                padding: "10px",
                textAlign: "left",
                
                height:"200px",
                background: "linear-gradient(to top, #cfd3d5, #53595c)",
                
              }}
            >
              Choose the accountType! 🎭📱<br />
              <Box flexGrow={1} display="flex" flexDirection={"column"} rowGap={0}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                  <Button
                    type="submit"
                    className="btn"
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                    onClick={() => handleAccountTypeChange(false)}
                  >
                    Individual
                  </Button>

                  <h1 style={{color:'light-grey', }}>Setup a personal Profile</h1>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                  <Button
                    type="submit"
                    className="btn"
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                    onClick={() => handleAccountTypeChange(true)}
                  >
                    Community
                  </Button>

                  <h1 style={{color:'light-grey'}}>Setup a group Profile for Events</h1>
                </div>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default UserAccountTypeView;
