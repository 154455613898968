import React from "react";
import toast from "react-hot-toast";
import { PiShareFatFill } from "react-icons/pi";

function Share({ postId }: { postId: string }) {
  return (
    <button
      className="text-gray-600 flex items-center space-x-2"
      onClick={() => {
        navigator.clipboard.writeText(
          `${window.location.origin}/news-feed/detail/${postId}`
        );
        toast.success("Post link copied successfully");
      }}
    >
      <PiShareFatFill size={20} />
      <p className="text-sm hidden md:block">share</p>
    </button>
  );
}

export default Share;
