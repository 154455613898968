import React, { useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { likeFeed } from "../../../../../api/news";
import { getCookie } from "../../../../../helpers/cookieHelper";
import toast from "react-hot-toast";

function LikePost({
  likesCount,
  postId,
}: {
  likesCount: number;
  postId: string;
}) {
  const [likes, setLikes] = useState<number>(likesCount);

  const handleLikePost = async () => {
    if (!getCookie("auth_token")) {
      toast.error(`Login to like the post`);
      return;
    }
    try {
      const { success, message } = await likeFeed(
        postId,
        getCookie("auth_token")
      );
      console.log({ message, success });
      if (success) {
        setLikes((p) => ++p);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(`Ooops error occured!`);
    }
  };
  return (
    <div className="flex items-center gap-x-2 text-gray-600">
      <AiFillLike
        className="cursor-pointer"
        onClick={handleLikePost}
        size={22}
      />
      <p className="text-sm hidden md:block">Likes</p>
    </div>
  );
}

export default LikePost;
