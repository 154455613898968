import { useEffect, useState } from "react";
import useUserStore from "../store/UserStore";
import { isLoggedIn } from "../helpers/authHelper";
import { getUserDetails } from "../api/users";
export const useGetCurrentUser = () => {
  const { currentUser, setCurrentUser } = useUserStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      const user = isLoggedIn();
      if (user && !currentUser) {
        const details = await getUserDetails(user.userId);
        setCurrentUser(details);
      }
      setIsLoading(false);
    };

    fetchUserDetails(); // Fetch user details only on mount

    // No need to include currentUser in the dependency array
  }, []);

  return { currentUser, setCurrentUser, isLoading };
};
