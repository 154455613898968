import { useEffect, useState } from "react";
import useUserStore from "../store/UserStore";
import useAuthStore from "../store/AuthStore";
import { getUserDetails } from "../api/users";
import { getCookie, removeCookie } from "../helpers/cookieHelper";
import { convertToken, isTokenExpired } from "../helpers/jwtHelper";
import { AuthStatusEnum } from "../types/globals";
export const useAuthentication = () => {
  const { currentUser, setCurrentUser } = useUserStore();
  const { authStatus, setAuthStatus } = useAuthStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const token = getCookie("auth_token");

        if (!token || isTokenExpired(token)) {
          setAuthStatus(AuthStatusEnum.unauthenticated);
          return;
        }

        if (authStatus === AuthStatusEnum.unauthenticated || !currentUser) {
          console.log(`Loading user`);
          const userFromToken = convertToken(token);
          const details = await getUserDetails(userFromToken?.userId);
          if (details.error) {
            setAuthStatus(AuthStatusEnum.unauthenticated);
            removeCookie("auth_token");
            return;
          }
          setCurrentUser(details);
          setAuthStatus(AuthStatusEnum.authenticated);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setAuthStatus(AuthStatusEnum.unauthenticated);

        // Handle error (e.g., set error state, display error message)
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [authStatus, currentUser, setCurrentUser, setAuthStatus]);
  const isAuthorized = () => {
    return authStatus === "authenticated" || authStatus === "pending";
  };
  return { currentUser, isLoading, authStatus, isAuthorized };
};
