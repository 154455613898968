import { create } from "zustand";
import { PublicPost } from "../types/globals";

type Store = {
  posts: PublicPost[];
  setPosts: (value: PublicPost[]) => void;
};

const usePostsStore = create<Store>()((set) => ({
  posts: [],
  setPosts: (value) => set((state) => ({ posts: value })),
}));

export default usePostsStore;
