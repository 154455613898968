export const BuisnessWhyJoinQuestion = [
    "A sense of belonging and a supportive network.",
    "Opportunities for volunteering and making a difference.",
    "Access to exclusive events and meet-ups.",
    "Insights into cutting-edge trends and technologies.",
    "A platform for expressing creativity and sharing ideas.",
    "Guidance in personal and professional development.",
    "Connections with like-minded individuals across the globe.",
    "A treasure trove of resources for learning and exploration.",
    "Engaging discussions and stimulating brainstorms.",
    "A safe space for sharing and healing.",
    "Encouragement to pursue passions and dreams.",
    "Collaboration opportunities on exciting projects.",
    "A deep dive into cultural experiences and global cuisines.",
    "Regular challenges to spark motivation and growth.",
    "A vibrant exchange of recipes, crafts, and DIY tips.",
    "First-hand advice from experts in various fields.",
    "A forum for advocating for social and environmental causes.",
    "Personalized recommendations for books, movies, and more.",
    "A calendar filled with virtual and in-person gatherings.",
    "An ever-expanding library of online courses and workshops.",
    
    ];