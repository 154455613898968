import { create } from "zustand";
import { AuthStatus, AuthStatusEnum } from "../types/globals";

type Store = {
  authStatus: AuthStatus;
  setAuthStatus: (value: AuthStatus) => void;
};

const useAuthStore = create<Store>()((set) => ({
  authStatus: AuthStatusEnum.pending,
  setAuthStatus: (value) => {
    set((state) => ({ state, authStatus: value }));
  },
}));

export default useAuthStore;
