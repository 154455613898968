import { create } from "zustand";

type Store = {
  isNotificationModalOpen: boolean;
  setIsNotificationModalOpen: (value: boolean) => void;
  isSearchUsersModalOpen: boolean;
  setIsSearchUsersModalOpen: (value: boolean) => void;
  isSelectingUserPending: boolean;
  setIsSelectingUserPending: (value: boolean) => void;
  userMenuOpen: boolean;
  setUserMenuOpen: (value: boolean) => void;
  theme: "light" | "dark" | undefined;
  setTheme: (theme: "light" | "dark") => void;
};

const useSharedStore = create<Store>()((set) => ({
  isNotificationModalOpen: false,
  setIsNotificationModalOpen: (value) =>
    set((state) => ({ isNotificationModalOpen: value })),

  isSearchUsersModalOpen: false,
  setIsSearchUsersModalOpen: (value) =>
    set((state) => ({ isSearchUsersModalOpen: value })),

  isSelectingUserPending: false,
  setIsSelectingUserPending: (value) =>
    set((state) => ({ isSelectingUserPending: value })),
  userMenuOpen: false,
  setUserMenuOpen: (value) => set((state) => ({ userMenuOpen: value })),
  theme: undefined,
  setTheme: (theme) => set((state) => ({ theme: theme })),
}));

export default useSharedStore;
