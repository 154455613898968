import React from "react";
import { LiaCommentSolid } from "react-icons/lia";
import { Link } from "react-router-dom";

function Comments({
  postId,
  commentsCount,
}: {
  postId: string;
  commentsCount: number;
}) {
  return (
    <Link
      to={`/news-feed/detail/${postId}`}
      className="flex items-center gap-x-2 text-gray-600"
    >
      <LiaCommentSolid className="cursor-pointer" size={22} />
      <p className="text-sm hidden md:block">Comments</p>
    </Link>
  );
}

export default Comments;
