import { useState } from "react";
import { BiCalendar, BiLocationPlus, BiTime } from "react-icons/bi";
import { deleteEvent, likeEvent, unlikeEvent } from "../../api/events";
import { getCookie } from "../../helpers/cookieHelper";
import "./Event.css";
import { useAuthentication } from "../../hooks/useAuthentication";
import { Link, useLocation } from "react-router-dom";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { handleFollowAction } from "../../services/FollowService";
import { FiUserCheck } from "react-icons/fi";
import { AiOutlineUserAdd } from "react-icons/ai";
import useUserStore from "../../store/UserStore";
import NavigationButton from "../util/NavigationButton";
import useCheckUserFollowed from "../../hooks/useCheckUserFollowed";

const EventDetails = ({ event, events, setEvents, mobile }) => {
  const communityOwner = event.communityOwner
  const token = getCookie("auth_token");
  const loggedUser = useAuthentication();
  const loggedUserId = loggedUser?.currentUser?._id;
  const [detailsVisibility, setDetailsVisibility] = useState({});
  const location = useLocation();
  const { currentUser, setCurrentUser } = useUserStore();
  const { isFollowed } = useCheckUserFollowed(communityOwner);
  const toggleDetails = (eventId) => {
    setDetailsVisibility((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };
  const eventId = { eventId: event._id };
  const countAttendees = (eventId) => {
    const event = events.find((event) => event._id === eventId);
    return event && communityOwner?.followers
      ? communityOwner?.followers.length
      : 0;
  };

  const formatDateWithoutYear = (dateString) => {
    const options = { month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteEvent({ eventId }, token);
      const updatedEvents = events.filter((event) => event._id !== eventId);
      setEvents(updatedEvents);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };
  const getCommunityOwnerId = (communityOwner) => {
    return typeof communityOwner === "object"
      ? communityOwner._id
      : communityOwner;
  };
  const communityOwnerId = getCommunityOwnerId(communityOwner);
  console.log("evenst", communityOwner)
  if (!event) return "OOPS";

  if (mobile) {
    return (
      <>
        <div className="event-details-mobile box">
          <div className="eventwrap-mobile">
            <div className="ribbon-2-mobile"> Event </div>
            {location.pathname === "/meetups" ? (
              <Link
                to={`/profile/${communityOwner.username}`}
                className="eventImageContainer-mobile"
              >
                <img
                  src={event.imageUrl}
                  alt="Event thumbnail"
                  loading="lazy"
                  className="h-full w-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/errorCat.gif";
                  }}
                />
              </Link>
            ) : (
              <div className="eventImageContainer-mobile">
                <img
                  src={event.imageUrl}
                  alt="Event thumbnail"
                  loading="lazy"
                  className="h-full w-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/errorCat.gif";
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                width: "100%",
                padding: "15px",
                paddingLeft: "20px",
                paddingRight: "20px"
                // minWidth:"300px"
              }}
            >
              <h5
                // style={{
                //   fontSize: "20px",
                // }}
                className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
              >
                {event.title}
              </h5>
              <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                <div style={{ display: "flex", flexShrink: "0" }}>
                  Event By {communityOwner.name ? communityOwner.name : communityOwner.username}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiLocationPlus
                    style={{ fontSize: "20px", marginLeft: "-3px" }}
                  />
                  <div style={{ paddingLeft: "5px" }}>{event.location}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiCalendar style={{ fontSize: "20px", marginLeft: "-3px" }} />
                  <div style={{ paddingLeft: "5px" }}>
                    {formatDateWithoutYear(event.date)}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiTime style={{ fontSize: "20px", marginLeft: "-3px" }} />
                  <div style={{ paddingLeft: "5px" }}>
                    {event.startTime} - {event.endTime}
                  </div>
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {communityOwner?.followers?.includes(loggedUserId)
                    ? `You are a member along with with ${countAttendees(event._id) - 1
                    } others`
                    : ` ${countAttendees(event._id)} member(s)`}
                </div>
                <div>
                  {detailsVisibility[event._id] ? (
                    <div>{event.details}</div>
                  ) : (
                    <div
                      style={{ cursor: "pointer", color: "rgb(160, 210, 215)" }}
                      onClick={() => toggleDetails(event._id)}
                    >
                      Read more ...
                    </div>
                  )}
                </div>
                {location.pathname === "/meetups" &&
                <div style={{paddingTop:"10px"}}>
                  <Link
                    to={`/profile/${communityOwner.username}`}
                    className="w-fit inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    View
                    <svg
                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </Link>
                  </div>}
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // paddingTop: "20px",
                    justifyContent: "space-between",
                  }}
                ></div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="event-details box">
        {location.pathname === "/profile" ? (
          <button
            onClick={() => handleDeleteEvent(event._id)}
            className="ribbon-2"
          >
            Delete Event
          </button>
        ) : (
          <div className="ribbon-2"> Event </div>
        )}
        <div className="eventImageContainer">
          <img
            src={event.imageUrl}
            alt="Event thumbnail"
            loading="lazy"
            className="h-full w-full object-cover"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/errorCat.gif";
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            width: "100%",
          }}
        >
          <div
            style={{
              paddingTop: "15px",
              fontSize: "20px",
              paddingBottom: "5px",
            }}
          >
            {event.title}
          </div>
          <div style={{ display: "flex" }}>
            Event By{" "}
            <div style={{ paddingLeft: "5px", textDecoration: "underline" }}>
              {" "}
              {communityOwner.name ? communityOwner.name : communityOwner.username}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiLocationPlus style={{ fontSize: "20px", marginLeft: "-3px" }} />
            <div style={{ paddingLeft: "5px" }}>{event.location}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiCalendar style={{ fontSize: "20px", marginLeft: "-3px" }} />
            <div style={{ paddingLeft: "5px" }}>
              {formatDateWithoutYear(event.date)}, {event.startTime} -{" "}
              {event.endTime}
            </div>
          </div>
          <div style={{ fontWeight: "bold" }}>
            {communityOwner?.followers?.includes(loggedUserId)
              ? `You are a member along with with ${countAttendees(event._id) - 1
              } others`
              : ` ${countAttendees(event._id)} member(s)`}
          </div>
          <div>
            {detailsVisibility[event._id] ? (
              <div>{event.details}</div>
            ) : (
              <div
                style={{ cursor: "pointer", color: "rgb(160, 210, 215)" }}
                onClick={() => toggleDetails(event._id)}
              >
                Read more ...
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {currentUser && communityOwnerId !== currentUser._id && (
                // <div className="md:space-x-2 flex items-center space-x-2">
                <button
                  style={{
                    backgroundColor: "rgb(59,130,246)",
                    padding: "6px 25px",
                    borderRadius: "199px",
                    color: "white",
                    // fontWeight:"bold",
                  }}
                  title={isFollowed ? "unfollow" : "follow"}
                  onClick={async () =>
                    await handleFollowAction({
                      isFollowed,
                      currentUser,
                      setCurrentUser,
                      user: communityOwner,
                    })
                  }
                  className="text-white flex items-center space-x-1.5  border duration-100  hover:border-gray-600 dark:hover:border-darkWhite  "
                >
                  {isFollowed ? (
                    <>
                      <span className="block">Unjoin Community</span>
                      <FiUserCheck size={20} />
                    </>
                  ) : (
                    <>
                      <span className="block">Join Community</span>
                      <AiOutlineUserAdd size={20} />
                    </>
                  )}
                </button>
              )}
              {location.pathname === "/meetups" && (
                <NavigationButton
                  communityOwnerUsername={communityOwner.username}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
