import React, { useLayoutEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

function OptimizedImage({
  hash,
  imageURL,
  alt,
  hashConfig = {
    width: 250,
    height: 400,
  },
}: {
  hash?: string;
  imageURL: string;
  alt: string;
  hashConfig?: {
    width: number;
    height: number;
  };
}) {
  const [imageLoading, setImageLoading] = useState(false);
  useLayoutEffect(() => {
    setImageLoading(true);
  }, []);
  return (
    <>
      {imageLoading && (
        <Blurhash
          hash={
            hash && hash !== "TODO: Hash"
              ? hash
              : "LEHV6nWB2yk8pyo0adR*.7kCMdnj"
          }
          className="w-full"
          width={hashConfig.width}
          height={hashConfig.height}
          resolutionX={32}
          resolutionY={32}
          punch={0}
        />
      )}
      <img
        src={imageURL}
        className={`${imageLoading ? "size-0" : "min-w-60 w-full max-w-none"}`}
        width={250}
        alt={alt}
        height={400}
        onLoad={() => {
          setImageLoading(false);
        }}
      />
    </>
  );
}

export default OptimizedImage;
