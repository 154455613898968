import { removeCookie, setCookie } from "./cookieHelper";
import { initiateSocketConnection } from "./socketHelper";

const isLoggedIn = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const loginUser = (user) => {
  // NOTICE: for the future move the keys to a enum
  removeCookie("auth_token");
  removeCookie("user");
  removeCookie("businessAccount");
  // NOTICE: we may won't store also the password
  localStorage.setItem("user", JSON.stringify(user));
  console.log('user',user)
  const { token, password, businessAccount, ...userData } = user;
  setCookie({
    name: "auth_token",
    value: token,
  });
  setCookie({
    name: "user",
    value: JSON.stringify(userData),
  });
  setCookie({
    name: "businessAccount",
    value: JSON.stringify(businessAccount),
  });
  initiateSocketConnection();
};

const logoutUser = () => {
  removeCookie("auth_token");
  removeCookie("user");
  removeCookie("businessAccount");
  localStorage.removeItem("user");
  initiateSocketConnection();
};

export { loginUser, isLoggedIn, logoutUser };
