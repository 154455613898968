import useSWR from "swr";
import { getUsersList } from "../services/UserService";
import useUserStore from "../store/UserStore";
import { useEffect } from "react";
import { UserDTO } from "../types/globals";

const useGetUsersList = () => {
  const { data, isLoading } = useSWR(
    "users-list",
    async () => await getUsersList()
  ) as {
    data: UserDTO[];
    isLoading: boolean;
  };
  const { users, setUsers } = useUserStore();
  useEffect(() => {
    if (!isLoading && users.length < data.length) {
      setUsers(data);
    }
  }, [isLoading]);
  return { isLoading, data, users, setUsers };
};

export default useGetUsersList;
