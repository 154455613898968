export const BuisnessToneQuestion = [

    "Supportive and empowering, fostering personal growth.",
    "Quirky and unconventional, celebrating uniqueness.",
    "Thoughtful and reflective, with a focus on personal well-being.",
    "Dynamic and fast-paced, for those who love to be on the cutting edge.",
    "Relaxed and serene, promoting mindfulness and tranquility.",
    "Passionate and determined, with a can-do attitude.",
    "Whimsical and playful, where imagination runs wild.",
    "Respectful and empathetic, where everyone feels heard.",
    "Adventurous and bold, for those who dare to dream.",
    "Resourceful and practical, sharing life hacks and advice.",
    "Cultured and sophisticated, with a taste for the finer things.",
    "Groundbreaking and avant-garde, always questioning the status quo.",
    "Humorous and light-hearted, because laughter is the best medicine.",
    "Nostalgic and sentimental, cherishing memories and traditions.",
    "Scholarly and intellectual, for the lifelong learners.",
    "Minimalistic and eco-friendly, promoting sustainable living.",
    "Luxurious and exclusive, for those who enjoy the finer details.",
    "Compassionate and caring, focusing on mental health and well-being.",
    "Mystical and spiritual, exploring the deeper meanings of life.",
    "Athletic and competitive, for sports enthusiasts and fitness buffs.",

    
    ];