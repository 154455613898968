import { useRef, ChangeEvent, Dispatch, SetStateAction } from "react";

function useInput() {
  const ref = useRef<HTMLInputElement>(null);
  const fileInputClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };
  const handleTextInputChange = <T>(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setState: Dispatch<SetStateAction<T>>
  ) => {
    const name = e.target.name as keyof T;
    const value = e.target.value as T[keyof T];

    setState((prev: T) => ({
      ...prev,
      [name]: value,
    }));
  };
  return { fileInputClick, ref, handleTextInputChange };
}

export default useInput;
