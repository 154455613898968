import { create } from "zustand";
import { PublicNewsPost } from "../types/globals";

type Store = {
  news: PublicNewsPost[];
  setNews: (value: PublicNewsPost[]) => void;
};

const useNewsStore = create<Store>()((set) => ({
  news: [],
  setNews: (value) => set((state) => ({ news: value })),
}));

export default useNewsStore;
