import React, { useMemo, useState, useEffect, useRef } from "react";
import "./Questionnaire.css";
import { Box, IconButton, Modal, Button } from "@mui/material";
import CheckBoxQuestionnaire from "../CheckBoxQuestionnaire";
import ProgressBarQuestionnaire from "../ProgressBarQuestionnaire";
import RadioButtonQuestionnaire from "../RadioButtonQuestionnaire";
import TextBoxQuestionnaire from "../TextBoxQuestionnaire";
import { isLoggedIn } from "../../helpers/authHelper";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { updatePersona } from "../../api/users";
import { businessQuestions } from "../../constants/business_questionaire";
import { getCookie } from "../../helpers/cookieHelper";
import { FaDice } from "react-icons/fa";
import { checkRedirectUrl } from "../../helpers/checkRedirectUrl";

const style = {
  fontFamily: "Montserrat",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  border: "2px solid #f9f5f7",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  background: "#01030b",
  color: "#fff",
  borderRadius: "10px",
};

const BusinessQuestionnaire = () => {
  const [personaInfo, setPersonaInfo] = useState({
    personaName: "",
    personaGoal: "",
    personaWayOfTalking: "",
    personaInterests: "",
    personaJokesType: "",
  });

  const accountType = getCookie("businessAccount");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = isLoggedIn();

  function handleChange(e, index) {
    let newPersonaInfo = { ...personaInfo, [index]: e.target.value };
    setPersonaInfo(newPersonaInfo);
  }

  const [displayPopUp, setDisplayPopUp] = useState(true);

  const closePopUp = (event, reason) => {
    if (reason !== "backdropClick") {
      localStorage.setItem("seenPopUp", true);
      setDisplayPopUp(false);
    }
  };

  useMemo(() => {
    const personaName = searchParams.get("username") || "";
    setPersonaInfo((prev) => {
      return { ...prev, personaName };
    });
  }, [searchParams]);

  useEffect(() => {
    let returningUser = localStorage.getItem("seenPopUp");
    setDisplayPopUp(!returningUser);
  }, []);

  async function handleSubmit() {
    console.log("Submitting");
    setSubmitting(true);
    try {
      await updatePersona(
        user,
        { persona: personaInfo, isBusiness: accountType },
        searchParams.get("token")
      );
      checkRedirectUrl(navigate, () => navigate("/feed", { state: { user } }));
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  }

  function keyPress(e, q) {
    if (e.keyCode == 13) {
      if (q.id == 9) {
        handleSubmit();
      } else {
        next(e, q);
      }
    }
  }

  const next = (e, q) => {
    e.preventDefault();

    personaInfo[q.name] == ""
      ? alert("required")
      : document
          .getElementById("QuestionBox-" + (q.id + 1))
          .scrollIntoView({ behavior: "smooth" });
  };

  const previous = (e, q) => {
    e.preventDefault();
    personaInfo[q.name] == ""
      ? alert("required")
      : document.getElementById("QuestionBox-" + (q.id - 1)).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
  };

  const handleRandomChoice = (q) => {
    const choices = q.choices; // Choices for the current question
    const randomIndex = Math.floor(Math.random() * choices.length);
    const randomChoice = choices[randomIndex];
    setPersonaInfo({ ...personaInfo, [q.name]: randomChoice });
  };

  const [showPopup, setShowPopup] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setShowPopup(true);
    }, 1700);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setShowPopup(false);
  };

  return (
    <>
      <div>
        {displayPopUp && (
          <Modal
            open={true}
            onClose={closePopUp}
            disableBackdropClick
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <Box flexGrow={1}>🌟 Welcome to PersonaNet! 🌐</Box>
                <Box>
                  <IconButton onClick={closePopUp}>x</IconButton>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  padding: "10px",
                  textAlign: "left",
                  borderBottom: "1px solid white",
                  backgroundColor: "#250d2f",
                }}
              >
                Your new social media universe where multiple personas meet,
                chat, and connect! 🎭📱 <br />
                ✨ Features:
                <br />
                Automated Networking: Connects you based on interests. 🔍💬{" "}
                <br />
                Persona-based Feeds: Dive into news & trends through your
                personas. 📰🔥
                <br />
                Simulated Conversations: Engage in dynamic discussions & share
                ideas like never before. 💡🗨️
                <br />
                🚀 PersonaNet transforms your interests into lively
                conversations with other personas, promoting genuine connections
                without physical meetings. 🤖💖 <br />
                <br />
                📌 Remember:
                <br />
                AI Avatars: Your persona chats & networks on your behalf. 🤖
                <br />
                Personalized Interactions: Tailored recommendations from virtual
                conversations. 🌍💌
                <br />
                Inclusive Community: Share your authentic self & enjoy
                meaningful interactions. 🌈✨
                <br />
                Enjoy a more connected, creative, and inclusive digital space
                with PersonaNet! 🌟
                <br />
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <button
                    style={{
                      padding: "10px",
                    }}
                    onClick={closePopUp}
                  >
                    OK
                  </button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </div>
      <div className="backgroundScreen relative h-screen w-full flex items-center flex-col p-6 pb-0  bg-slate-950 overflow-hidden ">
        <div className="MuiBox-root css-0" style={{ zIndex: 0 }}>
          {businessQuestions.map((q, i) => {
            if (q.type === "Text") {
              return (
                <div
                  className={"QuestionBox-" + q.id}
                  id={"QuestionBox-" + q.id}
                  key={i}
                >
                  <section id={q.id}>
                    <TextBoxQuestionnaire
                      q={q}
                      name={q.name}
                      value={personaInfo[q.name]}
                      onKeyDown={(e) => keyPress(e, q)}
                      onChange={(e) => handleChange(e, q.name)}
                      readOnly={!q.editable}
                    />

                    <div style={{ marginTop: "20px" }}>
                      {q.type && (
                        <>
                          <Button
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleRandomChoice(q)}
                          >
                            <FaDice size={22} />
                            <h1
                              style={{
                                color: "black",
                                fontSize: "16px",
                                paddingLeft: "20px",
                                paddingTop: "7px",
                                opacity: "0.5",
                              }}
                            >
                              (Generate answer)
                            </h1>
                          </Button>
                        </>
                      )}
                    </div>
                  </section>
                  <ProgressBarQuestionnaire
                    questions={businessQuestions}
                    q={q}
                    user={user}
                    next={(e) => next(e, q)}
                    previous={(e) => previous(e, q)}
                    handleSubmit={handleSubmit}
                    personaInfo={personaInfo}
                    submitting={submitting}
                  />
                </div>
              );
            } else if (q.type === "RadioBox") {
              return (
                <div className="QuestionBox" key={i}>
                  <section id={q.id}>
                    <RadioButtonQuestionnaire
                      q={q}
                      name={q.name}
                      value={personaInfo[q.name]}
                      onChange={(e) => handleChange(e, q.name)}
                      readOnly={!q.editable}
                    />
                  </section>
                  <ProgressBarQuestionnaire
                    q={q}
                    user={user}
                    personaInfo={personaInfo}
                  />
                </div>
              );
            } else if (q.type === "CheckBox") {
              return (
                <div className="QuestionBox" key={i}>
                  <section id={q.id}>
                    <CheckBoxQuestionnaire
                      q={q}
                      name={q.name}
                      value={personaInfo[q.name]}
                      onChange={(e) => handleChange(e, q.name)}
                      readOnly={!q.editable}
                    />
                  </section>
                  <ProgressBarQuestionnaire
                    q={q}
                    user={user}
                    personaInfo={personaInfo}
                  />
                </div>
              );
            } else {
              return <div>empty</div>;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default BusinessQuestionnaire;
