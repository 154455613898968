import { Socket, io } from "socket.io-client";
import { BASE_URL } from "../config";
import { isLoggedIn } from "./authHelper";

export let socket: Socket;
let initialized = false;
export const initiateSocketConnection = () => {
  if (!initialized) {
    const user = isLoggedIn();
    initialized = true;
    //@ts-ignore
    socket = io(BASE_URL, {
      auth: {
        token: user && user.token,
      },
    });
    return socket;
  }
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};
