import React, { useRef } from "react";
import { Box, Card, Typography } from "@mui/material";
import HorizontalStack from "../../../util/HorizontalStack";
import NotificationCard from "./NotificationCard";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import useSharedStore from "../../../../store/SharedStore";
import useUserStore from "../../../../store/UserStore";

function NotificationModal() {
  const { notifications } = useUserStore();
  const { setIsNotificationModalOpen } = useSharedStore();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsNotificationModalOpen(false));

  const containerStyle = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    backgroundColor: "white",
  };

  return (
    <div
      style={{
        width: "300px",
        position: "absolute",
        left: "60%",
        top: "100px",
        zIndex: "400",
        borderRadius: "80x",
        boxShadow: "2px 1px 7px -4px",
        overflow: "auto",
        maxHeight: "350px",
      }}
      className="hidden__scroll"
      ref={ref}
    >
      <Box style={containerStyle}>
        {notifications.length === 0 ? (
          <Card variant="outlined">
            <HorizontalStack justifyContent="space-between">
              <Typography>No notification yet</Typography>
            </HorizontalStack>
          </Card>
        ) : (
          notifications.map((notification: any, idx: number) => (
            <NotificationCard
              key={notification._id}
              notification={notification}
            />
          ))
        )}
      </Box>
    </div>
  );
}

export default NotificationModal;
