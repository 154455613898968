import { useEffect, useState } from "react";
import { getNotifications } from "../services/UserService";
import useUserStore from "../store/UserStore";
import { db } from "../config/firebaseConfig";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { INotification } from "../types/globals";
import { convertToken } from "../helpers/jwtHelper";
import { getCookie } from "../helpers/cookieHelper";

const useGetNotifications = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { notifications, setNotifications, currentUser } = useUserStore();
  useEffect(() => {
    console.log("Getting notification for first time");
    if (notifications.length === 0) {
      setIsLoading(true);
      try {
        (async () => {
          const data = await getNotifications();
          setNotifications(data);
        })();
      } catch (error) {
        setNotifications([]);
      }
      setIsLoading(false);
    }
  }, [notifications.length, setNotifications]);
  useEffect(() => {
    console.log("IN effect of subsribing");
    let unsubscribe;
    const userId = convertToken(getCookie("auth_token") || "")?.userId;
    if (currentUser && userId !== undefined) {
      const q = query(
        collection(db, "notifications"),
        where("notifiedUsers", "array-contains", userId),
        orderBy("createdAt", "desc")
      );
      unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const notifications = QuerySnapshot.docs.map((doc) => {
          const data = { ...doc.data() };
          return { ...data, _id: doc.id } as INotification;
        });
        setNotifications(notifications);
      });
      return unsubscribe;
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser, setNotifications]);

  return { notifications, setNotifications, isLoading };
};
export default useGetNotifications;
