import React from "react";
import { PublicNewsPost } from "../../../../types/globals";
import PostContent from "./read/TopicContent";
import LikePost from "./actions/LikePost";
import Share from "./actions/Share";
import Comments from "./actions/Comments";
import OptimizedImage from "../../partials/OptimizedImage";

function Feeds({ post }: { post: PublicNewsPost }) {
  return (
    <div className="flex my-1 gap-x-4 rounded-md bg-gray-200 shadow-mlg shadow-gray-100 dark:shadow-gray-900 dark:bg-[#0D0D0D] p-4">
      <div className="rounded-md overflow-hidden min-w-40 w-40 max-h-[250px] md:max-h-max">
        <OptimizedImage
          hash={post.image_url_hash}
          imageURL={post.image_url || `https://robohash.org/${post._id}`}
          alt={post.topic}
        />
      </div>

      <div className="md:flex-1 flex flex-col lg:flex-row justify-between">
        <PostContent {...post} />
        <div className="flex md:flex-col-reverse space-x-8 md:space-x-0">
          <LikePost likesCount={post.likes.length} postId={post._id} />
          <Comments commentsCount={post.comments.length} postId={post._id} />
          <div className="md:flex-1 text-gray-600">
            <Share postId={post._id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feeds;
