export const BuisnessTypeOfPeopleQuestion = [
    "Digital nomads and remote workers seeking connection.",
    "History buffs and cultural enthusiasts.",
    "Tech wizards and innovation seekers.",
    "Peaceful warriors and mindfulness practitioners.",
    "Fashion-forward individuals and trendsetters.",
    "Culinary explorers and food lovers.",
    "Literary aficionados and writers.",
    "Environmental activists and green warriors.",
    "Musicians, artists, and creatives.",
    "Fitness enthusiasts and health-conscious individuals.",
    "Board game aficionados and puzzle solvers.",
    "DIY makers and crafters.",
    "Parents looking for family-friendly activities and advice.",
    "Travel enthusiasts dreaming of their next adventure.",
    "Film buffs and movie aficionados.",
    "Entrepreneurs and business-minded individuals.",
    "Animal lovers and pet owners.",
    "Gardeners and plant enthusiasts.",
    "Volunteers and philanthropists.",
    "Students and academics seeking knowledge and discussion.",
    
    ];