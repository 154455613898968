import { BASE_URL } from "../config";
import { put } from "../lib/Fetcher";

const likeFeed = async (postId, token) => {
  try {
    const res = await fetch(`${BASE_URL}api/newsPosts/${postId}/like/`, {
      method: "PUT",
      headers: {
        "x-access-token": token,
      },
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const createFeedComment = async (postId, comment, token) => {
  console.log('postId, comment, token',postId, comment, token)
  try {
    const response = await put(`api/newsPosts/${postId}/comment`, {
      userId: JSON.parse(localStorage.getItem("user")).userId,
      content: comment,
    },token);
    
    return response;
  } catch (err) {
    console.log(err);
  }
};

export {
  likeFeed,
  createFeedComment
};
