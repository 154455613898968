import React, { useRef } from "react";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import useSharedStore from "../../../../store/SharedStore";
import FindUsers from "../../../FindUsers";

function UsersSearchModal() {
  const { setIsSearchUsersModalOpen } = useSharedStore();
  const ref = useRef<HTMLElement>(null);
  useClickOutside(ref, () => setIsSearchUsersModalOpen(false));

  return (
    <section
      style={{
        width: "300px",
        borderWidth: "1px",
        borderColor: "black",
        position: "absolute",
        left: "40%",
        top: "100px",
        zIndex: "400",
        maxHeight: "400px",
        overflow: "auto",
        boxShadow: "2px 1px 7px -4px",
      }}
      className="hidden__scroll"
      ref={ref}
    >
      <FindUsers />
    </section>
  );
}

export default UsersSearchModal;
