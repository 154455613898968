import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { PublicPost } from "./../../../../../types/globals";
type PostProps = {
  post: PublicPost;
};
function DetailContent({ post }: PostProps) {
  const postContent = useMemo(() => {
    return post.content.split("-");
  }, [post]);
  return (
    <div className="space-y-2">
      <h4 className="text-gray-700 dark:text-gray-500 text-xl">{post.topic}</h4>
      {postContent.map((c) => (
        <p key={post._id + "_" + c} className="dark:text-gray-400 text-xs my-[1px]">
          {c}
        </p>
      ))}
    </div>
  );
}

export default DetailContent;
