import { BASE_URL } from "../config";

const API_URL = BASE_URL;

export const get = async (path, token = "") => {
  try {
    const res = await fetch(API_URL + path, {
      //@ts-ignore
      headers: {
        "x-access-token": token,
      },
    });
    return await res.json();
  } catch (error) {
    return {};
  }
};

export const put = async (path, data, token = "") => {
  try {
    const res = await fetch(API_URL + path, {
      //@ts-ignore
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    return {};
  }
};
export const post = async (path, data, token = "") => {
  try {
    const res = await fetch(API_URL + path, {
      //@ts-ignore
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    return {};
  }
};
