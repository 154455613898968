import { Button, Card, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSWRConfig } from "swr";
import usePostsStore from "../../../../../store/PostStore";
import toast from "react-hot-toast";
import { getCookie } from "../../../../../helpers/cookieHelper";
import { createFeedComment } from "../../../../../api/news";

function CommentFeed({ post }) {
  const [comment, setComment] = useState("");
  const { posts, setPosts } = usePostsStore();
  const { mutate } = useSWRConfig();

  const handleCommenting = async (e) => {
    try {
        const { success, message, post: updatedPost } = await createFeedComment(
          post._id,
          comment,
          getCookie("auth_token")
        );
        if (success) {
          const newPosts = posts.map((p) => {
            if (p._id === post._id) {
              p.comments = updatedPost.comments;
            }
            return p;
          });
          setPosts(newPosts);
          toast.success(message);
        }else{
          toast.error(message);
        }
        mutate(`post-${post._id}`);
        setComment("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex my-1 gap-x-4 rounded-md bg-gray-200 shadow-mlg shadow-gray-100 dark:shadow-gray-900 dark:bg-[#0D0D0D] p-4">
      <div className="flex w-full items-center justify-between gap-x-3">
        <textarea
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          placeholder="comment.."
          rows={3}
          className="flex-1 block border bg-transparent text-gray-300 placeholder:text-gray-300 border-white rounded-md p-3 focus:outline-none"
        />
      </div>
      <button
          onClick={handleCommenting}
          className="text-white flex items-center gap-1"
        >
          <AiOutlineArrowLeft size={25} />
        </button>
    </div>
  );
}

export default CommentFeed;
