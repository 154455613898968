export const BuisnessInfoQuestion = [
    "Latest tech gadgets and software breakthroughs.",
    "Mindfulness practices and spiritual growth.",
    "Home decorating and interior design trends.",
    "Sustainable living and zero-waste tips.",
    "Personal finance and investment strategies.",
    "Art techniques and gallery openings.",
    "Historical facts and archaeological discoveries.",
    "Mental health support and self-care tips.",
    "Travel guides and hidden gems.",
    "Language learning and cultural exchange.",
    "DIY projects and crafting tutorials.",
    "Upcoming concerts and music festivals.",
    "Entrepreneurship and startup culture.",
    "Plant-based recipes and nutrition advice.",
    "Photography tips and creative challenges.",
    "Science news and innovative research findings.",
    "Parenting tips and family dynamics.",
    "Writing workshops and literature discussions.",
    "Sports updates and fitness routines.",
    "Environmental news and conservation efforts.",
    
    ];