import React, { useState, useEffect } from "react";
import "./views/Questionnaire.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  fontFamily: "Montserrat",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  border: "2px solid #f9f5f7",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  background: "#01030b",
  color: "#fff",
  borderRadius: "10px",
};

const CheckBoxQuestionnaire = (props) => {
  let question = props.q;
  return (
    <>
      <Typography
        variant="h6"
        color="white"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <div className="numberCircle">{question.id}</div>
        {question.question}
      </Typography>
      <label style={{ marginTop: "10px", color: "#a9a7a7" }}>
        {question.hint}
      </label>
      <FormGroup
        name={props.name}
        value={props.value || null}
        onChange={props.onChange}
      >
        {question.options.map((datum, i) => (
          <FormControlLabel
            label={datum}
            key={i}
            value={datum}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            control={
              <Checkbox
                size="medium"
                color="primary"
                //required={props.readOnly}
              />
            }
          />
        ))}
      </FormGroup>
    </>
  );
};

export default CheckBoxQuestionnaire;
