import React from "react";
import TopicFeeds from "../ui/features/public_news/TopicFeeds";
import { useParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function NewsTopic() {
  const { topic } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col overflow-hidden max-h-svh">
      <div className=" bg-gray-200 rounded-md dark:bg-[#0D0D0D] shadow-md dark:shadow-sm shadow-gray-100 dark:shadow-gray-950 flex items-center gap-x-5 p-4 mb-4">
        <button
          onClick={() => {
            navigate(`/news-feed`);
          }}
          className="w-6 h-6 bg-gray-300 dark:bg-gray-700 rounded-lg flex items-center justify-center"
        >
          <RiArrowLeftLine size={22} />
        </button>
        <h4 className="text-gray-700 dark:text-gray-500 text-xl">
          Viewing News Feed for {topic}
        </h4>
      </div>
      <TopicFeeds topic={topic || ""} />
    </div>
  );
}

export default NewsTopic;
