import { create } from "zustand";
import { INotification, UserDTO, UserDetails } from "../types/globals";

type Store = {
  users: UserDTO[];
  setUsers: (value: UserDTO[]) => void;
  suggestedUsers: UserDTO[];
  setSuggestedUsers: (value: UserDTO[]) => void;
  currentUser: UserDetails | undefined;
  setCurrentUser: (value: UserDetails | undefined) => void;
  notifications: INotification[];
  setNotifications: (value: INotification[]) => void;
  selectedUser: UserDTO | undefined;
  setSelectedUser: (value: UserDTO | undefined) => void;
  profileMode: "VIEW" | "ADMIN";
  setProfileMode: (value: "VIEW" | "ADMIN") => void;
  activeUsers: { [key in string]: string };
  setActiveUsers: (value: { [key in string]: string }) => void;
};

const useUserStore = create<Store>()((set) => ({
  users: [],
  setUsers: (value: UserDTO[]) => set((state) => ({ users: value })),
  suggestedUsers: [],
  setSuggestedUsers: (value: UserDTO[]) =>
    set((state) => ({ suggestedUsers: value })),
  currentUser: undefined,
  setCurrentUser: (value: UserDetails | undefined) =>
    set((state) => ({ currentUser: value })),
  notifications: [],
  setNotifications(value) {
    set((state) => ({ notifications: value }));
  },
  selectedUser: undefined,
  setSelectedUser(value) {
    set((state) => ({ selectedUser: value }));
  },
  profileMode: "VIEW",
  setProfileMode(value) {
    set((state) => ({ profileMode: value }));
  },
  activeUsers: {},
  setActiveUsers(value) {
    set((state) => ({ activeUsers: value }));
  },
}));

export default useUserStore;
