import { useLocation } from "react-router-dom";
import EventDetails from "./EventDetails";
import { useState } from "react";
import CommunityEventModal from "../CommunityComponents/CommunityEventModal";

const EventsAll = ({ events, setEvents, mobile }) => {
  
  return (
    <>
      <div className={`events-list`}>
        {events.map((event, index) => (
          <div key={index} className="event-card">
            <EventDetails
              events={events}
              setEvents={setEvents}
              event={event}
              mobile={mobile}
            />
          </div>
        ))}
      </div>
    </>
  );
};
export default EventsAll;
