import React from "react";
import useGetNews from "../../../../hooks/useGetNews";
import PostSkeleton from "../../loaders/PostSkeleton";
import Feeds from "./Feeds";
function TopicFeeds({ topic }: { topic: string }) {
  const { news, error, isLoading } = useGetNews(topic);

  return (
    <div className="mb-20 overflow-scroll invisible__scroll">
      {news.length === 0 && !isLoading && <p>No posts for this feed yet</p>}
      {!!news.length &&
        news.map((feed) => <Feeds post={feed} key={feed._id} />)}
      {isLoading &&
        Array.from({ length: 10 })
          .fill(2)
          .map((elem: any, idx) => <PostSkeleton key={idx + elem} />)}
      {error && <h4>OOOPS ERROR OCCURED</h4>}
    </div>
  );
}

export default TopicFeeds;
