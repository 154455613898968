import React from "react";
import Loading from "./Loading";
import UserEntry from "./UserEntry";
import useGetUsersList from "../hooks/useGetUsersList";

const FindUsers = () => {
  const { data, isLoading, users, setUsers } = useGetUsersList();
  //Handle onchange
  const handleSearchChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value;

    if (value === "" && users.length < data.length) {
      setUsers(data);
    }

    const result = data.filter((u) =>
      u.username.toLowerCase().includes(value.toLowerCase())
    );
    setUsers(result);
  };
  if (isLoading) return <Loading />;

  return (
    <div className="border border-gray-600 pb-2 relative rounded-md shadow-sm overflow-auto max-h-screen hidden__scroll mb-4 space-y-4">
      <div className="sticky left-0 md:sticky w-1/2 md:w-full md:bg-gray-800 z-10 top-0 p-2">
        <div className="relative z-0 w-full mb-2 mt-2 group">
          <input
            type="text"
            name="flowting_username_search"
            id="flowting_username_search"
            className="block py-2.5 px-0 w-full text-sm text-gray-300 bg-transparent border-0 border-b-2 border-gray-600 appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            onChange={handleSearchChange}
          />
          <label
            htmlFor="flowting_username_search"
            className="peer-focus:font-medium absolute text-sm text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Username..
          </label>
        </div>
      </div>

      <div className="flex md:flex-col gap-3 m-2 mb-4">
        {!!users.length &&
          users.map((user) => (
            <UserEntry
              userId={user._id}
              username={user.username}
              key={user.username}
              profileUrl={user.profileUrl}
            />
          ))}
      </div>
    </div>
  );
};

export default FindUsers;
