import { useEffect, useState } from "react";
import useNewsStore from "../store/NewsStore";
import { get } from "../lib/Fetcher";

function useGetNews(topic: string) {
  let title = topic;
  const { news, setNews } = useNewsStore();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    try {
      setIsLoading(true);
      (async () => {
        const data = await get(`api/news-feed/${topic}`);
        setNews(data.feed);
      })();
    } catch (error: any) {
      setError(true);
    } finally {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { title, news, setNews, error, isLoading };
}

export default useGetNews;
