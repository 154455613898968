// React
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
// Config
import theme from "./theme";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { initiateSocketConnection } from "./helpers/socketHelper";
import "./global.css";

// Components
import NotificationModal from "./components/ui/actions/notification/NotificationModal";
import UsersSearchModal from "./components/ui/actions/users/UsersSearchModal";

// Hooks
import useSharedStore from "./store/SharedStore";
import useGetNotifications from "./hooks/useGetNotifications";
import { useAuthentication } from "./hooks/useAuthentication";
import PageLoader from "./components/ui/loaders/PageLoader";
import { AuthStatusEnum } from "./types/globals";
import NewsTopic from "./components/views/NewsTopic";
import NewsFeedDetails from "./components/ui/features/public_news/NewsFeedDetails";
import Meetups from "./components/views/Meetups";
import UserAccountTypeView from "./components/auth/UserAccountTypeView";
import BusinessQuestionnaire from "./components/views/BusinessQuestionnaire";
import ContactForm from "./components/ui/features/contact/contactForm";

// Views

const ExploreView = React.lazy(() => import("./components/views/ExploreView"));
const EditPersonaView = React.lazy(() =>
  import("./components/views/EditPersonaView")
);
const EditProfileView = React.lazy(() =>
  import("./components/views/EditProfileView")
);
const ForgotPassword = React.lazy(() =>
  import("./components/views/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/views/ResetPassword")
);

const Feed = React.lazy(() => import("./components/views/Feed"));
const News = React.lazy(() => import("./components/views/News"));
const LoginView = React.lazy(() => import("./components/views/LoginView"));

const PostDetails = React.lazy(() =>
  import("./components/ui/features/public_post/PostDetails")
);
const CommunityPostDetails = React.lazy(() =>
  import("./components/ui/community-posts/CommunityPostDetails")
);
const SignupView = React.lazy(() => import("./components/views/SignupView"));

const MessagePage = React.lazy(() =>
  import("./components/FirebaseComponents/MessagePage")
);
const Layout = React.lazy(() => import("./components/layout/Layout"));
const MainContainer = React.lazy(() =>
  import("./components/layout/MainContainer")
);
const Questionnaire = React.lazy(() =>
  import("./components/views/Questionnaire")
);
const Settings = React.lazy(() => import("./components/views/Settings"));

const AuthUserProfileView = React.lazy(() =>
  import("./components/views/AuthUserProfileView")
);
const MatchFeed = React.lazy(() => import("./components/views/MatchFeed"));
const UserPosts = React.lazy(() => import("./components/views/UserPosts"));
const Users = React.lazy(() => import("./components/views/Users"));
const User = React.lazy(() => import("./components/views/User"));

// Firebase configuration
// TODO: @Tan0699 configuration need to be in a separated file
const firebaseConfig = {
  apiKey: "AIzaSyBVbYmo5NRpMqdbHSPByvI4vVAng5aMNiQ",
  authDomain: "checkout-fe492.firebaseapp.com",
  databaseURL: "https://checkout-fe492-default-rtdb.firebaseio.com/",
  projectId: "checkout-fe492",
  storageBucket: "checkout-fe492.appspot.com",
  messagingSenderId: "762556089421",
  appId: "1:762556089421:web:c3a0ef02d98cd05c018804",
  measurementId: "G-ZN0W6HPD17",
};
let sockerInitialized = false;
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
function App() {
  if (!sockerInitialized) {
    initiateSocketConnection();
    sockerInitialized = true;
  }

  const { isNotificationModalOpen, isSearchUsersModalOpen } = useSharedStore();
  const { authStatus, isAuthorized } = useAuthentication();
  useGetNotifications();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <div>
            <Toaster />
          </div>
          {isNotificationModalOpen && <NotificationModal />}
          {isSearchUsersModalOpen && <UsersSearchModal />}
          <CssBaseline />
          <Routes>
            <Route
              path="/feed"
              element={
                <Layout>
                  <Feed />
                </Layout>
              }
            />
            <Route
              path="/explore"
              element={
                <Layout>
                  <ExploreView />
                </Layout>
              }
            />
            <Route
              path="/matching"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <MatchFeed />
                  </Layout>
                )
              }
            />
            <Route
              path="/news-feed"
              element={
                <Layout>
                  <News />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <ContactForm/>
                </Layout>
              }
            />
            <Route
              path="/news-feed/:topic"
              element={
                <Layout>
                  <NewsTopic />
                </Layout>
              }
            />
            <Route
              path="/news-feed/detail/:id"
              element={
                <Layout>
                  <NewsFeedDetails />
                </Layout>
              }
            />
            <Route
              path="/meetups"
              element={
                <Layout>
                  <Meetups />
                </Layout>
              }
            />

            <Route
              path="/settings"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <Settings />
                  </Layout>
                )
              }
            />
            <Route
              path="/posts/:id"
              element={
                <Layout>
                  <PostDetails />
                </Layout>
              }
            />
            <Route
              path="/newsPosts/:id"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <CommunityPostDetails />
                  </Layout>
                )
              }
            />
            <Route
              path="/firebase"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <MessagePage />
                  </Layout>
                )
              }
            />
            <Route path="/persona/individual" element={<Questionnaire />} />
            <Route
              path="/persona/business"
              element={<BusinessQuestionnaire />}
            />
            <Route
              path="/firebase/:roomRef"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <MessagePage />
                  </Layout>
                )
              }
            />

            <Route
              path="/users"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <Users />
                  </Layout>
                )
              }
            />
            <Route
              path="/profile"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <AuthUserProfileView />
                  </Layout>
                )
              }
            />
            <Route
              path="/admin/posts"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <UserPosts />
                  </Layout>
                )
              }
            />
            <Route
              path="/profile/edit"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <Layout>
                    <EditProfileView />
                  </Layout>
                )
              }
            />
            <Route
              path="/profile/:username"
              element={
                <Layout>
                  <User />
                </Layout>
              }
            />
            <Route
              path="/"
              element={
                <Layout>
                  <Feed />
                </Layout>
              }
            />
            <Route
              path="/login"
              element={
                authStatus === AuthStatusEnum.authenticated ? (
                  <Navigate to="/feed" />
                ) : (
                  <MainContainer>
                    <LoginView />
                  </MainContainer>
                )
              }
            />
            <Route
              path="/login/success"
              element={
                <MainContainer>
                  <UserAccountTypeView />
                </MainContainer>
              }
            />
            <Route
              path="/signup"
              element={
                <MainContainer>
                  <SignupView />
                </MainContainer>
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/persona/edit"
              element={
                !isAuthorized() ? (
                  <Navigate to={"/login"} />
                ) : (
                  <EditPersonaView />
                )
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
