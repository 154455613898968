import { getCookie } from "../helpers/cookieHelper";

const { BASE_URL } = require("../config");

export const createEvent = async (payload) => {
  try {
    const res = await fetch(BASE_URL + "api/events/create", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token"),
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error creating event:", err);
    throw err;
  }
};

export const editEvent = async (payload) => {
  try {
    const res = await fetch(BASE_URL + "api/events/edit", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token"),
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error updating event:", err);
    throw err;
  }
};

export const likeEvent = async (payload, token) => {
  try {
    const res = await fetch(BASE_URL + "api/events/like", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error liking event:", err);
    throw err;
  }
};

export const unlikeEvent = async (payload, token) => {
  try {
    const res = await fetch(BASE_URL + "api/events/unlike", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error unliking event:", err);
    throw err;
  }
};

export const attendEvent = async (payload, token) => {
  try {
    const res = await fetch(BASE_URL + "api/events/attend", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error attending event:", err);
    throw err;
  }
};

export const unattendEvent = async (payload, token) => {
  try {
    const res = await fetch(BASE_URL + "api/events/unattend", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error unattending event:", err);
    throw err;
  }
};

export const fetchEventsByOwnerId = async (communityOwnerId,token) => {
  try {
    const res = await fetch(BASE_URL + `api/events/owner`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({communityOwnerId }),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error fetching events by owner ID:", error);
    throw error;
  }
};

export const fetchAllEvents = async () => {
  try {
    const res = await fetch(BASE_URL + `api/events/all-events`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error fetching all events:", error);
    throw error;
  }
};

export const fetchLocations = async () => {
  try {
    const res = await fetch(BASE_URL + `api/events/locations`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error fetching event locations: ", error);
    throw error;
  }
};

export const fetchUpcomingEvents = async () => {
  try {
    const res = await fetch(BASE_URL + `api/events/upcoming-events`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error fetching upcoming events:", error);
    throw error;
  }
};

export const deleteEvent = async (payload) => {
  try {
    console.log("payloadsss",payload)
    const res = await fetch(BASE_URL + `api/events/delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": getCookie("auth_token")
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (err) {
    console.error("Error deleting event:", err);
    throw err; 
  }
};