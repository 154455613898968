import React from "react";

function PostSkeleton() {
  return (
    <div className=" animate-pulse my-1 bg-gray-300 dark:bg-gray-800 rounded-md p-2">
      <div className="h-4 bg-gray-400 rounded-lg dark:bg-gray-600 w-48 mb-4"></div>
      <div className="h-4 bg-gray-400 rounded-lg dark:bg-gray-600 max-w-[360px] mb-2.5"></div>
      <div className="h-4 bg-gray-400 rounded-lg dark:bg-gray-600 mb-2.5"></div>
      <div className="h-4 bg-gray-400 rounded-lg dark:bg-gray-600 max-w-[330px] mb-2.5"></div>
    </div>
  );
}

export default PostSkeleton;
