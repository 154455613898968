import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

const CustomTimePicker = ({ label, value, onChange }) => {
  const [timeValue, setTimeValue] = useState('');

  useEffect(() => {
    if (value) {
      const [time, modifier] = value.split(' ');
      let [hours, minutes] = time.split(':');
      
      hours = parseInt(hours, 10); 
      if (modifier === 'PM' && hours !== 12) {
        hours = hours + 12;
      } else if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }
    setTimeValue(`${hours.toString().padStart(2, '0')}:${minutes}`);
    }
  }, [value]);

  const handleTimeChange = (e) => {
    let [hours, minutes] = e.target.value.split(':');
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12; 

    const newTimeValue = `${hours}:${minutes} ${ampm}`;
    onChange(newTimeValue);
  };

  return (
    <Box display="flex" flexDirection="column">
       <label>{label}</label>
      <input
        id="time-picker"
        type="time"
        value={timeValue}
        onChange={handleTimeChange}
        style={{ padding: '10px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' ,color:"blue"}}
      />
    </Box>
  );
};

export default CustomTimePicker;