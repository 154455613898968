export type UserDTO = {
  _id: string;
  username: string;
  email: string;
  biography: string;
  profileUrl: string;
  persona: string;
  postsCount: number;
  posts: PublicPost[];
  followersCount: number;
  followingsCount: number;
  businessAccount?: boolean;
  members?: string[];
  communityPosts?: PublicPost[];
  name?: string;
  preferedLocation?: string;
  socialLinks: SocialLink[];
  createdAt: string;
  status?:string;
};

export type Follow = {
  _id: string;
  username: string;
};

export type UserDetails = UserDTO & {
  followings: Follow[];
  followers: Follow[];
};

export type INotification = {
  _id: string;
  content: string;
  type:
    | "like"
    | "comment"
    | "follow"
    | "public_post_creation"
    | "private_post_creation";
  notifiedUsers: number[];
  username: string;
  postId?: number;
  isRead: boolean;
};
export type UserPersona = {
  username: string;
  _id: string;
  email: string;
  profileUrl: string;
};
export type PublicPost = {
  _id: string;
  personas: {
    username: string;
    _id: string;
    email: string;
    profileUrl: string;
  }[];
  content: string;
  likes: string[];
  comments: {
    userId: string;
    username: string;
    content: string;
    createdAt: string;
    _id: string;
  }[];
  thumbnail?: string;
  thumbnailHash?: string;
  topic: string;
  type: string;
  image_url: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserDTO;
  __v: number;
};

export type PublicNews = {
  id: number;
  name: string;
  image: string;
  imageHash: string;
  description: string;
  followers: string;
};

export type PublicNewsPost = {
  _id: string;
  parent_community: string;
  personas: {
    username: string;
    _id: string;
    email: string;
    profileUrl: string;
  }[];
  content: string;
  likes: string[];
  comments: {
    userId: string;
    username: string;
    content: string;
    createdAt: string;
    _id: string;
  }[];
  topic: string;
  type: string;
  image_url: string;
  image_url_hash?: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type CommunityPost = {
  _id: string;
  parent_community: string;
  personas: {
    username: string;
    _id: string;
    email: string;
    profileUrl: string;
  }[];
  content: string;
  likes: string[];
  comments: {
    userId: string;
    username: string;
    content: string;
    createdAt: string;
    _id: string;
  }[];
  topic: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type UpdateUserRequest = {
  username: string;
  biography?: string;
  name?: string;
  email?: string;
  preferedLocation?: string;
  profileUrl?: string;
};
export type SocialLink = {
  name?: string;
  url: string;
};

export type AuthStatus =
  | "authenticated"
  | "unauthenticated"
  | "unauthorized"
  | "pending";
export const AuthStatusEnum: Record<AuthStatus, AuthStatus> = {
  authenticated: "authenticated",
  unauthenticated: "unauthenticated",
  unauthorized: "unauthorized",
  pending: "pending",
};

export type MatchingPost = PublicPost & {
  matchingPercentage: number;
};

export type Matching = {
  _id: string;
  matchWith: UserPersona;
  userId: string;
  matchingPercentage: number;
  type: "global_match" | "community_match";
  matchingContext: string;
  community?: {
    _id: string;
    name: string;
    username: string;
    profileUrl: string;
  };
};
