import React from 'react';
import './views/Questionnaire.css';
import { Box, LinearProgress, Typography } from '@mui/material';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'; // Import arrow icons

const ProgressBarQuestionnaire = (props) => {
  const { questions, personaInfo, q, previous, next, handleSubmit, submitting } = props;
  let question = q;

  return (
    <>
      <Box display="flex" alignItems="center" p={3}>
        <Box width="100%" mr={1}>
          <Typography variant="body2" color="black">
            {question.id} / {questions.length}
          </Typography>
          <LinearProgress
            thickness={5}
            variant="determinate"
            value={(question.id / questions.length) * 100}
            sx={{
              backgroundColor: '#3d3e42',
              '& .MuiLinearProgress-bar': {
                background: '#6873c8',
              },
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="black">{`${Math.round(
            (question.id / questions.length) * 100
          )}%`}</Typography>
        </Box>
      </Box>
      <div className="next">
        {question.id === 1 ? (
          ''
        ) : (
          <button
            className="linkButtonLeft"
            onClick={previous}
          >
            <FaArrowLeft style={{ color: 'black' }} /> {/* Use FaArrowLeft icon */}
          </button>
        )}
        {question.id === questions.length ? (
          <button
            className="submit"
            onClick={handleSubmit}
            disabled={submitting}
          >
            Submit
          </button>
        ) : (
          <button
            className="linkButtonRight"
            onClick={next}
          >
            <FaArrowRight style={{ color: 'black' }} /> {/* Use FaArrowRight icon */}
          </button>
        )}
      </div>
    </>
  );
};

export default ProgressBarQuestionnaire;
