import { BusinessPersonaGeneratorKeys } from "./types/shared";
import { BuisnessWhyJoinQuestion } from "../components/views/BuisnessWhyJoinQuestion";
import { BuisnessToneQuestion } from "../components/views/BuisnessToneChoices";
import { BuisnessTypeOfPeopleQuestion } from "../components/views/BuisnessTypeOfPeopleChoices";
import { BuisnessInfoQuestion } from "../components/views/BuisnessInfoChoices";

export type Question = {
  id: number;
  question: string;
  text: string;
  example?: null | string;
  examples?: string[];
  hint: null | string;
  type: "text" | "range" | "Text" | "CheckBox" | "RadioBox";
  name: keyof BusinessPersonaGeneratorKeys;
  editable?: boolean;
  value?: string | number;
  options: string[];
  required?: boolean;
  choices?: string[];
};

export const businessQuestions: Question[] = [
  {
    id: 1,
    text: "What should people get out of joining your community?",
    question: "What should people get out of joining your community?",
    hint: "What can you educate from your business persona? For eg: Car Detailing",
    name: "personaGoal",
    type: "Text",
    options: [
      "Easygoing and friendly",
      "Funny and clever",
      "Deep and thoughtful",
      "Strong and bold",
    ],
    editable: true,
    choices: BuisnessWhyJoinQuestion
  },
  {
    id: 2,
    text: "What is the tone and style of your community?",
    question: "What is the tone and style of your community?",
    hint: "What should your business persona known for? For eg: AutoSPA",
    name: "personaWayOfTalking",
    type: "Text",
    options: [
      "To make new friends",
      "To keep up with the latest news and trends",
      "To talk about things they like",
      "To get help or advice",
    ],
    editable: true,
    choices: BuisnessToneQuestion
  },
  {
    id: 3,
    text: "What kind of people will fit in with your community best?",
    question: "What kind of people will fit in with your community best?",
    hint: "What does your business persona like to do or talk about the most? For eg: About Cars",
    name: "personaInterests",
    type: "Text",
    options: [
      "Meeting people",
      "Following news and trends",
      "Discussing hobbies or interests",
      "Looking for guidance or support",
    ],
    editable: true,
    choices: BuisnessTypeOfPeopleQuestion 
  },

  {
    id: 4,
    text: "What type of information does your community discuss most?",
    question: "What type of information does your community discuss most?",
    hint: "What type of facts does your business persona like the most? For eg: Car Maintenance",
    name: "personaJokesType",
    type: "Text",
    options: ["Sarcastic jokes", "Dry humor", "Silly, over-the-top jokes"],
    editable: true,
    choices: BuisnessInfoQuestion
  },
  {
    id: 5,
    text: "What are some hobbies of people in your community?",
    question: "What are some hobbies of people in your community?",
    hint: "What type of facts does your business persona like the most? For eg: Car Maintenance",
    name: "personaHobbies",
    type: "Text",
    options: ["Sarcastic jokes", "Dry humor", "Silly, over-the-top jokes"],
    editable: true,
    choices: BuisnessInfoQuestion
  },
  {
    id: 6,
    text: "Any interesting info about your Community?",
    question: "Any interesting info about your Community?",
    hint: "What type of facts does your business persona like the most? For eg: Car Maintenance",
    name: "personaInfo",
    type: "Text",
    options: ["Sarcastic jokes", "Dry humor", "Silly, over-the-top jokes"],
    editable: true,
    choices: BuisnessInfoQuestion
  },
];
