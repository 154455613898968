import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { markNotification } from "../../../../services/UserService";
import { INotification } from "../../../../types/globals";
import toast from "react-hot-toast";
import useSharedStore from "../../../../store/SharedStore";

const NotificationCard = ({
  notification,
}: {
  notification: INotification;
}) => {
  const { setIsNotificationModalOpen } = useSharedStore();

  const navigate = useNavigate();
  const cardStyle = {
    marginBottom: "2px",
    borderRadius: "8px",
    padding: "6px",
    backgroundColor: !notification.isRead ? "#cfceff61" : "white",
  };
  const contentStyle = {
    padding: "8px",
    display: "flex",
    gap: "6px",
  };

  const handleNotificationClick = async () => {
    //TODO:update the notification state
    setIsNotificationModalOpen(false);
    if (!notification.isRead) {
      markNotification(notification._id);
    }
    if (notification.type === "follow") {
      navigate(`/profile/${notification.username}`);
    }
    if (
      [
        "like",
        "comment",
        "public_post_creation",
        // "community_post_creation",
      ].indexOf(notification.type) !== -1
    ) {
      navigate(`/posts/${notification.postId}`);
    }
    if (notification.type === "private_post_creation") {
      // TODO: handle this
      toast.success(`Will be soon implemented`);
    }
  };

  return (
    <Button fullWidth onClick={handleNotificationClick}>
      <Card variant="outlined" style={cardStyle}>
        <CardContent style={contentStyle}>
          {/*TODO:@YounesAfF handle profile URL {["private_post_creation", "public_post_creation"].indexOf(
           */}
          <Typography variant="body2" fontSize={12}>
            {notification.content}
          </Typography>
        </CardContent>
      </Card>
    </Button>
  );
};

export default NotificationCard;
