import React from "react";
import "./views/Questionnaire.css";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

const RadioButtonQuestionnaire = (props) => {
  let question = props.q;

  return (
    <>
      <Typography
        variant="h6"
        color="white"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <div className="numberCircle">{question.id}</div>
        {question.question}
      </Typography>
      <label style={{ marginTop: "10px", color: "#a9a7a7" }}>
        {question.hint}
      </label>
      <RadioGroup
        name={props.name}
        value={props.value || null}
        onChange={props.onChange}
      >
        {question.options.map((datum, index) => (
          <FormControlLabel
            label={datum}
            key={index}
            value={datum}
            control={<Radio 
            //required={props.readOnly} 
            />}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default RadioButtonQuestionnaire;
