import { NavigateFunction } from "react-router-dom";
import { getCookie, removeCookie } from "./cookieHelper";

export const checkRedirectUrl = (navigate: NavigateFunction, callback: any) => {
  const redirectURL = getCookie("redirectTo");
  if (redirectURL) {
    console.log(
      `You were redirected to ${redirectURL} by a previous login attempt`
    );
    removeCookie("redirectTo");
    navigate(redirectURL);
  } else {
    callback();
  }
};
