import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EventDetails from './EventDetails';
import { fetchAllEvents, fetchLocations, fetchUpcomingEvents } from '../../api/events';
import EventsAll from './EventsAll';

function Meetups() {
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [locations, setLocations] = useState(['All']);
    const [locationFilter, setLocationFilter] = useState('All');
    const [showAllEvents, setShowAllEvents] = useState(false);
    const navigate = useNavigate();
    const [width, setWindowWidth] = useState(0);
    const mobile = width < 800;

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };
    useEffect(() => {
        const initialize = async () => {
            try {
                const locationsData = await fetchLocations();
                setLocations(['All', ...locationsData]);
                const allEvents = await fetchAllEvents();
                setEvents(allEvents);
                setFilteredEvents(allEvents);
            } catch (error) {
                console.error("Failed to initialize data:", error);
            }
        };
        initialize();
    }, []);

    useEffect(() => {
        const filterEvents = () => {
            if (locationFilter === 'All') {
                setFilteredEvents(showAllEvents ? events : events.filter(event => new Date(event.date) >= new Date()));
            } else {
                const filtered = events.filter(event => event.location === locationFilter && (showAllEvents || new Date(event.date) >= new Date()));
                setFilteredEvents(filtered);
            }
        };
        filterEvents();
    }, [locationFilter, events, showAllEvents]);

    const handleLocationChange = (e) => {
        setLocationFilter(e.target.value);
    };
    const handleShowAllEventsChange = (e) => {
        setShowAllEvents(e.target.checked);
    };
    return (
        <div className="max-h-screen w-full overflow-scroll invisible__scroll">
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-end", padding: "5px", paddingRight: "10px" }}>
                <label style={{ padding: "4px", paddingRight: "10px", border: "2px solid black" }}>
                    <input
                        type="checkbox"
                        checked={showAllEvents}
                        onChange={handleShowAllEventsChange}
                    />
                    Include Past Events
                </label>
                <select onChange={handleLocationChange} value={locationFilter}
                    style={{ padding: "4px", border: "2px solid black", borderRadius: "4px", outline: "none" }}
                >
                    {locations?.map((location, index) => (
                        <option key={index} value={location}>
                            {location}
                        </option>
                    ))}
                </select>
            </div>
            <div className="max-h-screen p-2 w-full overflow-scroll invisible__scroll">
                <EventsAll events={filteredEvents} setEvents={setEvents} mobile={mobile} />
            </div>
        </div>
    );
}


export default Meetups