import { useMemo } from "react";
import { useGetCurrentUser } from "./useGetCurrentUser";

import { UserDTO } from "../types/globals";
function useCheckUserFollowed(user: UserDTO | undefined) {
  const { currentUser } = useGetCurrentUser();
  const isFollowed = useMemo(() => {
    if (currentUser && user) {
      if (currentUser?.followings) {
        return currentUser.followings.some(
          (f) => f.username === user?.username
        );
      }
      return false;
    }
    return false;
  }, [currentUser, user]);
  return { isFollowed };
}

export default useCheckUserFollowed;
