import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { get } from "../../../../lib/Fetcher";
import Circle from "../../loaders/Circle";
import Share from "./actions/Share";
import LikePost from "./actions/LikePost";
import Comments from "./actions/Comments";
import DetailContent from "./read/DetailContent";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import CommentFeed from "./actions/CommentFeed";
const Comment = ({ comment }) => {
  const userProfileUrl = `https://robohash.org/${comment.username}`;

  return (
    <div className="mb-2 flex items-start flex-col justify-center rounded-md border border-gray-900 p-3">
      <div className="flex items-start gap-4">
        <img
          src={userProfileUrl}
          alt="User profile"
          className="block w-9 h-9 bg-gray-200 rounded-full"
        />
        <h3 className="text-[18px] font-bold text-gray-500">
          {comment.username}
        </h3>
      </div>
      <div className="pl-[52px] text-gray-400 text-sm font-medium flex items-center justify-between w-full">
        <p>{comment.content}</p>
      </div>
      <p className="text-gray-400  font-medium text-xs">
        {moment(comment.createdAt).fromNow()}
      </p>
    </div>
  );
};
function NewsFeedDetails() {
  const { id: postId } = useParams();
  const { data: post, isLoading } = useSWR(
    `post-${postId}`,
    async () => await get(`api/newsPosts/${postId}`, null)
  );
  const navigate = useNavigate();

  return (
    <div className="flex flex-col overflow-hidden max-h-svh">
      {isLoading ? (
        <Circle />
      ) : (
        <div className="mb-20 overflow-scroll invisible__scroll">
          <div className=" bg-gray-200 rounded-md dark:bg-[#0D0D0D] shadow-md dark:shadow-sm shadow-gray-100 dark:shadow-gray-950 flex items-center gap-x-5 p-4 mb-4">
            <button
              onClick={() => {
                navigate(`/news-feed/${post.parent_community}`);
              }}
              className="w-6 h-6 bg-gray-300 dark:bg-gray-700 rounded-lg flex items-center justify-center"
            >
              <RiArrowLeftLine size={22} />
            </button>
            <h4 className="text-gray-700 dark:text-gray-500 text-xl">
              Viewing Feed
            </h4>
          </div>
          <div className="flex my-1 gap-x-4 rounded-md bg-gray-200 shadow-mlg shadow-gray-100 dark:shadow-gray-900 dark:bg-[#0D0D0D] p-4">
            <div
              className="rounded-md bg-red-600 w-44 overflow-hidden"
              style={{ maxHeight: "176px" }}
            >
              <img
                src={post.image_url}
                alt="Post thumbnail"
                loading="lazy"
                className="h-full w-full object-cover"
              />
            </div>

            <div className="flex-1">
              <DetailContent post={post} key={`content-${post._id}`} />
            </div>
            <div className="flex flex-row items-center justify-between mt-4 md:flex-col md:justify-end md:space-y-3 md:items-start">
              <LikePost likesCount={post.likes.length} postId={post._id} />
              <Comments
                commentsCount={post.comments.length}
                postId={post._id}
              />
              <Share postId={post._id} />
            </div>
          </div>
          <div>
            <CommentFeed post={post} key={`actions-${post._id}`} />
          </div>
          <div className="flex my-1 gap-x-4 rounded-md bg-gray-200 shadow-mlg shadow-gray-100 dark:shadow-gray-900 dark:bg-[#0D0D0D] pb-4">
            <div className="flex-row w-full items-center gap-x-5 p-4">
              <p className="text-white font-medium text-lg">Comments</p>
              {(!post.comments || post.comments.length === 0) && (
                <Typography variant="subtitle2" color="white" gutterBottom>
                  No comments yet
                </Typography>
              )}
              <div>
                <Stack spacing={1}>
                  {post.comments.map((comment) => (
                    <Comment comment={comment} key={comment._id} />
                  ))}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsFeedDetails;
