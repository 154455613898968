import React from "react";
import { TbLoaderQuarter } from "react-icons/tb";

function PageLoader() {
  return (
    <div className="h-screen absolute inset-0 z-50 bg-gray-800 bg-opacity-50">
      <div className="absolute left-1/2 font-bold top-1/2 flex items-center gap-2">
        <TbLoaderQuarter className="block animate-spin" />
        <div> Loading...</div>
      </div>
    </div>
  );
}

export default PageLoader;
