import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: put values in env file
const firebaseConfig = {
  apiKey: "AIzaSyBVbYmo5NRpMqdbHSPByvI4vVAng5aMNiQ",
  authDomain: "checkout-fe492.firebaseapp.com",
  databaseURL: "https://checkout-fe492-default-rtdb.firebaseio.com/",
  projectId: "checkout-fe492",
  storageBucket: "checkout-fe492.appspot.com",
  messagingSenderId: "762556089421",
  appId: "1:762556089421:web:c3a0ef02d98cd05c018804",
  measurementId: "G-ZN0W6HPD17",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
export { auth, db, database, storage };
