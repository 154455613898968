import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationButton = ({ communityOwnerUsername }) => {
  const navigate = useNavigate();

  return (
    <div style={{ paddingLeft: "20px" }}>
      <button
        style={{
          backgroundColor: "rgb(230,230,230)",
          padding: "6px 20px",
          borderRadius: "199px",
          color: "black",
          fontWeight: "bold"
        }}
        onClick={() => navigate(`/profile/${communityOwnerUsername}`)}
      >
        View Event Page
      </button>
    </div>
  );
};

export default NavigationButton;