import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createEvent, editEvent } from "../../api/events";
import CustomTimePicker from "./customTimePicker";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../config/firebaseConfig";
import useInput from "../../hooks/useInput";
const style = {
  fontFamily: "Montserrat",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // height:"90vh",
  maxWidth: "500px",
  border: "2px solid #f9f5f7",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  background: "#01030b",
  color: "#fff",
  borderRadius: "10px",
  // overflowY: "auto",
  maxHeight: "90vh",
};
const CommunityEventModal = ({
  open,
  handleClose,
  userId,
  isEditMode,
  eventData,
  onEventCreatedOrUpdated,
}) => {
  console.log("eventdata", eventData)
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [payload, setPayload] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [eventId, setEventId] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const { fileInputClick, ref, handleTextInputChange } = useInput();
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  useEffect(() => {
    if (isEditMode && eventData) {
      setTitle(eventData.title || '');
      setDetails(eventData.details || '');
      setLocation(eventData.location || '');
      setDate(eventData.date || '');
      setStartTime(eventData.startTime || '');
      setEndTime(eventData.endTime || '');
      setImageUrl(eventData.imageUrl || '');
      setImagePreviewUrl(eventData.imageUrl || '');
      setEventId((eventData._id || ''));
    } else {
      resetForm();
    }
  }, [eventData, isEditMode]);
  const navigate = useNavigate();
  const upload = async (file) => {
    if (file) {
      const t = toast.loading("Uploading");
      const fileRef = storageRef(storage, `/communityEvent_url/${file.name}`);
      await uploadBytes(fileRef, file);
      const savedFileUrl = await getDownloadURL(fileRef);
      setImageUrl(savedFileUrl);
      setImagePreviewUrl(savedFileUrl);
      // setPayload((prevPayload) => ({
      //   ...prevPayload,
      //   imageUrl: savedFileUrl,
      // }));
      toast.success("Uploaded", { id: t });
    }
  };
  const submitForm = async () => {
    const updatedPayload = {
      ...payload,
      title,
      details,
      location,
      date,
      startTime,
      endTime,
      imageUrl,
      communityOwner: userId,
      eventId
    };
    const loadingToast = toast.loading("Creating Event...");
    try {
      let updatedEvent;
      if (isEditMode) {
        updatedEvent = await editEvent(updatedPayload)
      }
      else {
        updatedEvent = await createEvent(updatedPayload)
      }
      console.log("updatedeventtt", updatedEvent)
      onEventCreatedOrUpdated(updatedEvent);
      toast.dismiss(loadingToast);
      toast.success("Community created successfully!");
      handleClose();
      setTitle("");
      setDetails("");
      // setImageUrl("");
      setLocation("");
      setDate(null);
      setStartTime("");
      setEndTime("");
    } catch (error) {
      console.error("Error creating community:", error);
      toast.error("Failed to create community", { id: loadingToast });
    }
  };
  const resetForm = () => {
    setTitle('');
    setDetails('');
    setLocation('');
    setDate('');
    setStartTime('');
    setEndTime('');
    setImageUrl('');
    setImagePreviewUrl('');
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setPayload([]);
        resetForm();
      }}
      aria-labelledby="create-community-modal-title"
      aria-describedby="create-community-modal-description"
    >
      <Box sx={style}>
        <Box
          alignItems="center"
          style={{
            borderBottom: "1px solid white",
          }}
        >
          <Box flexGrow={1}> {!!isEditMode ? "Edit Event" : "Create an Event"}</Box>
          <Box>
            <IconButton
              color="#fff"
              onClick={() => {
                handleClose();
                setTitle("");
                setDetails("");
                // setImageUrl("");
                setLocation("");
                setDate(null);
              }}
            >
              <label style={{ color: "#fff" }}>x</label>
            </IconButton>
          </Box>
        </Box>
        <Box
          alignItems="center"
          style={{
            padding: "10px",
            textAlign: "left",
            borderBottom: "1px solid white",
            background: "linear-gradient(to top right, #cfd3d5, #53595c)",
            overflow: "auto"
          }}
        >
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />
          <input
            onChange={(e) => {
              if (e.target.files) {
                upload(e.target.files[0]);
              }
            }}
            ref={ref}
            type="file"
            className="hidden"
          />
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center', 
            margin: '10px'
          }}>
            {imagePreviewUrl ? (
              <img src={imagePreviewUrl} alt="Preview" style={{ width: '100%', maxWidth: '200px', height: 'auto' }} />
            ) : (
              <Typography>No Image Selected</Typography>
            )}
            <button
              onClick={() => {
                fileInputClick();
              }}
              className="max-w-xs w-2/3 border border-gray-300 rounded-md duration-150 bg-gray-300 dark:bg-gray-800 dark:text-gray-400 p-3 text-sm font-medium tracking-wide uppercase"
            >
              {isEditMode ? "Change" : "Insert"} Community Event Image
            </button>
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel id="event-location-label">Event Location</InputLabel>
            <Select
              labelId="event-location-label"
              id="event-location"
              value={location}
              label="Event Location"
              onChange={(e) => setLocation(e.target.value)}
            >
              <MenuItem value="New York">New York</MenuItem>
              <MenuItem value="San Francisco">San Francisco</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Event Date"
            type="date"
            value={date}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CustomTimePicker
            label="Start Time"
            value={startTime}
            onChange={(newStartTime) => setStartTime(newStartTime)}
          />
          <CustomTimePicker
            label="End Time"
            value={endTime}
            onChange={(newEndTime) => setEndTime(newEndTime)}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}></Box>
          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            {isEditMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommunityEventModal;
