import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import UserAvatar from "./UserAvatar";
import { FaEye } from "react-icons/fa";
import { getUser } from "../api/users";
import useUserStore from "../store/UserStore";
import useSharedStore from "../store/SharedStore";

const UserEntry = ({
  username,
  userId,
  profileUrl,
}: {
  username: string;
  userId?: string;
  profileUrl: string;
}) => {
  const { setSelectedUser } = useUserStore();
  const { setIsSelectingUserPending } = useSharedStore();

  const handleUserSelection = async () => {
    setIsSelectingUserPending(true);
    const user = await getUser({ username });
    setSelectedUser(user);
    localStorage.setItem("selectedUser", JSON.stringify(user));
    setIsSelectingUserPending(false);
  };

  return (
    <div
      onClick={handleUserSelection}
      className="bg-gray-700 cursor-pointer p-2 rounded-sm shadow-sm shadow-gray-800 hover:bg-gray-500 duration-150"
    >
      <HorizontalStack justifyContent="space-between" key={username}>
        <HorizontalStack>
          <UserAvatar
            width={30}
            height={30}
            profileUrl={profileUrl}
            username={username}
          />
          <p className="text-sm font-medium text-gray-400">
            {username.substring(0, 10)}...
          </p>
        </HorizontalStack>
        <button onClick={handleUserSelection} className="text-gray-400">
          <FaEye size={22} />
        </button>
      </HorizontalStack>
    </div>
  );
};

export default UserEntry;
