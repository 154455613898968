import { JwtPayload, jwtDecode } from "jwt-decode";

export const isTokenExpired = (token?: string) => {
  if (!token) return true;
  const { exp: expirationDate } = jwtDecode(token);
  if (expirationDate && Date.now() < expirationDate * 1000) return false;
  return true;
};
export const convertToken = <T>(
  token?: string
): (JwtPayload & { userId: string } & T) | null => {
  if (!token) return null;
  return jwtDecode(token);
};
